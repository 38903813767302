import { createContext } from 'react';

export const initialState = {
  test: ''
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'testStore':
      return {
        ...state,
        test: action.payload
      };
    default:
      return state;
  }
};

export const Context = createContext(undefined);
