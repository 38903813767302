import { FC } from 'react';
import styles from './assets/Footer.module.scss';

interface Props {
  className?: object;
}

const Footer: FC<Props> = () => {
  return <footer className={styles.footer}>footer</footer>;
};

export default Footer;
