import { FC } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import styles from './assets/Main.module.scss';

const MainLayout: FC = ({ children }) => {
  return (
    <div className={styles.root} id="root">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default MainLayout;
