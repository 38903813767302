import App from 'next/app';
import 'isomorphic-fetch';
import Provider from '../context/provider';
import PageLayout from '../layouts/Main';

export interface Props {
  Component: any;
  pageProps: any;
}

class MyApp extends App<Props> {
  // A somewhat hacky way to detect when a user is using a mouse or keyboard
  // We should remove this when the :focus-visible is supported by more major browsers.
  handleFirstTab = e => {
    if (e.keyCode === 9) {
      document.body.classList.add('keyboard');
      window.removeEventListener('keydown', this.handleFirstTab);
      window.addEventListener('mousedown', this.handleMouseDownOnce);
    }
  };

  handleMouseDownOnce = () => {
    document.body.classList.remove('keyboard');

    window.removeEventListener('mousedown', this.handleMouseDownOnce);
    window.addEventListener('keydown', this.handleFirstTab);
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', this.handleFirstTab);
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <Provider>
        <PageLayout>
          <Component {...pageProps} />
        </PageLayout>
      </Provider>
    );
  }
}

export default MyApp;
