import { FC } from 'react';
import styles from './assets/Header.module.scss';

interface Props {
  className?: object;
}

const Header: FC<Props> = ({ children }) => {
  const cloudinary = 'https://dev-pixels-cache.icelandairlabs.com/upload/';
  return (
    <header className={styles.header}>
      <img style={{ height: 70 }} src={`${cloudinary}icelandair/bltbfbff19cec7c56b4.svg`} alt="Icelandair logo" />
      {children}
    </header>
  );
};

export default Header;
